import React from 'react'
import { NavCard, NavCardContent, NavCardCover, NavCardActions, NavCardButton } from '../../../../atoms/NavigationCard'

export const FinalizeSelector = ({ event, onClick }) => {
	const path = '/assets/images/graphics/navigation-cards/vidday-event-page-finalize'
	const description = 'Pay and publish'
	return (
		<NavCard onClick={onClick}>
			<NavCardCover path={path} />
			<NavCardContent title={'Finalize'} description={description} />
			<NavCardActions>
				<NavCardButton />
			</NavCardActions>
		</NavCard>
	)
}
